import { Button, Container, Dropdown, Modal, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { checkProvider } from "../common/checkProvider";
import { Handleconnect, formatAddress, formatBalance, formatChainAsNum } from "../common/connectWallet";
import { useEffect, useState } from "react"
import { Link, useLocation, matchPath, useParams } from "react-router-dom";
import { setWalletAddress, setwalletConnected, setwalletBalance } from '../store/index_slice'
import { useSelector, useDispatch } from 'react-redux'
import ConnectWalletPopup from "../common/ConnectWalletPopup";
import swal from "sweetalert";
import { LinkContainer } from 'react-router-bootstrap'
import { all } from "axios";
import { tokenAbi } from "../config/tokenAbi";
import { tokenAddress } from "../config/config";

const { Web3 } = require('web3');
const Header = () => {
  const walletAddress = useSelector((state) => state.reducer.walletAddress)
  const walletBalance = useSelector((state) => state.reducer.walletBalance)
  const [dataProvider, setDataProvider] = useState('');
  const dataProviders = [{ value: "", label: "Data Providers" }, { value: "artemis", label: "Artemis" }, { value: "coinglass", label: "CoinGlass" }, { value: "coinmarketcap", label: "CoinMarketCap" }, { value: "dappradar", label: "DappRadar" }, { value: "defillama", label: "DefiLlama" }, { value: "intotheblock", label: "IntoTheBlock" }, { value: "lunarcrush", label: "LunarCrush" }, { value: "twitter", label: "Twitter" }, { value: "uncategorized", label: "Uncategorized" }];
  const [showconnect, setShowconnect] = useState(false)
  const handleCloseConnect = () => {
    setShowconnect(false)
    window.location.reload()
  }
  const handleShowConnect = () => setShowconnect(true);
  const location = useLocation()

  const isHomeRoute = matchPath({ path: "/", end: true }, location.pathname) ||
    matchPath({ path: "/:dataProvider", end: true }, location.pathname);

  const params = useParams();

  useEffect(() => {
    const dataProviderParam = params.dataProvider;
    if (dataProviderParam) {
      setDataProvider(dataProviderParam);
    }
  }, []);

  useEffect(() => {
    // checkConnection()
    // setHasProvider(checkProvider())
    console.log("=====walletAddress===", walletAddress)
    if (connected_wallet) {
      let walletbalance = getbalance(connected_wallet)
      console.log("=====walletbalance===", walletbalance)
    }
    formatChainAsNum()

  }, [])



  //   const iswallet = useSelector((state)=>state.reducer.walletConnected)
  try {
    window.ethereum.on('accountsChanged', async () => {
      // connectWallet()
    })
    window.ethereum.on('disconnect', async () => {
      dispatch(setwalletConnected(false))
      dispatch(setWalletAddress({ accounts: [] }))

      // location.clear()

    });
  } catch (error) {
    if (error == "ProviderError: Provider not available. Use `.setProvider` or `.provider=` to initialize the provider.") {
      swal("warning", "Please Install Metamask", "warning")
    }
  }
  const dispatch = useDispatch()

  const [hasProvider, setHasProvider] = useState(false)
  const [balanceUser, setbalanceUser] = useState(0)
  const [iswallet, setiswallet] = useState(localStorage.getItem("is_walletConnected"))
  const [connected_wallet, setconnected_wallet] = useState(localStorage.getItem("connected_wallet"))
  const disconnect = () => {
    dispatch(setwalletConnected(false))
    dispatch(setWalletAddress({ accounts: [] }))
    setconnected_wallet()
    setiswallet()
    localStorage.clear()
    swal("Success", "Wallet disconnected successfully", "success").then(() => {
      localStorage.clear();
      window.location.reload()
    })
  }
  const connectWallet = async () => {

    let accounts = await Handleconnect()
    console.log("====accounts====", accounts);
    if (accounts == "meta_error") {
      // handleClose()
    } else {
      if (accounts) {
        dispatch(setwalletConnected(true))
        dispatch(setWalletAddress(accounts))
        handleShowConnect()
        // setShow(false)
      }
    }

  }
  const checkConnection = () => {
    const webb3 = new Web3(window.ethereum);
    webb3.eth.getAccounts()
      .then(async (addr) => {
        if (addr.length == 0) {
          dispatch(setwalletConnected(true))
          dispatch(setWalletAddress(addr))
          localStorage.clear()
        }

      });
  }

  const getbalance = async (walletAddress) => {
    try {
      const webb3 = new Web3(window.ethereum);
      let toContract = new webb3.eth.Contract(tokenAbi, tokenAddress);
      console.log("walletAddress>>>>>>", walletAddress);
      let userBalance = await toContract.methods.balanceOf(walletAddress).call();
      let balance = formatBalance(userBalance.toString())
      dispatch(setwalletBalance(balance))
      console.log("====balance====", balance)
      setbalanceUser(balance)
    } catch (err) {
      console.log("err>>>>>", err);
    }
  }

  const findDataProviderLabel = (value) => {
    const provider = dataProviders.find(provider => provider.value === value);
    return provider ? provider.label : null;
  };

  const [showFolio, setShowFolio] = useState(false);

  const handleCloseFolio = () => setShowFolio(false);
  const handleShowFolio = () => setShowFolio(true);

  return (
    <>
      <div className="header">
        <ConnectWalletPopup show={showconnect} handleClose={handleCloseConnect} connectWallet={connectWallet} />
        <Navbar expand="lg" >
          <Container>
            <Navbar.Brand href="/"><img src={require("../assets/images/logo.png")} alt="logo" /></Navbar.Brand>
            <Nav className="ms-auto notification-mob">
            <Nav.Link href="" className="notification-icon" onClick={handleShowFolio}>
                  <i className="fa fa-bell" aria-hidden="true"></i>
                  <span className="dot-icon"></span>
                </Nav.Link>
                </Nav>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="/" className={(location.pathname == "/") && " active"}>Home</Nav.Link>
                <Nav.Link href="/about-us" className={(location.pathname == "/about-us") && " active"}>About us</Nav.Link>
                {/* <Nav.Link href="/how-it-works" className={(location.pathname == "/how-it-works") && " active"}>How it works</Nav.Link> */}
                <Nav.Link href="/contact-form" className={(location.pathname == "/contact-us") && " active"}>Contact us </Nav.Link>
                <Nav.Link href="/redeem" className={(location.pathname == "/redeem") && " active"}>Redeem FOLIO</Nav.Link>

                <NavDropdown title="Leaderboard" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/leaderboard" className={(location.pathname == "/leaderboard") && " active"}>Points Program</NavDropdown.Item>
                  <NavDropdown.Item href="/referrals">Referrals</NavDropdown.Item>
                  {/* <NavDropdown.Item href="/redeem">Redeem</NavDropdown.Item> */}
                </NavDropdown>

                {/* <Nav.Link href="/leaderboard" className={(location.pathname == "/leaderboard") && " active"}>Points Program </Nav.Link> */}



                {isHomeRoute && findDataProviderLabel(dataProvider) ?
                  <NavDropdown title={dataProvider ? findDataProviderLabel(dataProvider) : "Data Providers"} id="basic-nav-dropdown">

                    {dataProviders.map((data, index) => {
                      return (
                        <NavDropdown.Item href={"/" + data.value} > {data.label}</NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  :
                  null
                }
                <div className="connect-wallet-btn" >
                  {iswallet || walletAddress.length > 0 ?
                    // <Nav.Link href="#">{formatAddress(connected_wallet ? connected_wallet : walletAddress[0])}</Nav.Link>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic" >
                        {formatAddress(connected_wallet ? connected_wallet : walletAddress[0])}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item className="disabled" ><img src={require("../assets/images/usd-coin.png")} alt="img" />{walletBalance ? walletBalance : balanceUser}</Dropdown.Item>
                        <Dropdown.Item href={location.pathname == "/transaction-management" ? "javascript:void(0)" : "/transaction-management"}><img src={require("../assets/images/transaction.svg").default} alt="transaction" />Transactions</Dropdown.Item>
                        <Dropdown.Item href="" onClick={disconnect}><img src={require("../assets/images/disconnect.png")} alt="disconnect" />Disconnect</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <Nav.Link href="#" onClick={connectWallet}>Connect Wallet</Nav.Link>

                  }


                </div>
                <Nav.Link href="" className="notification-icon web-notification" onClick={handleShowFolio}>
                  <i className="fa fa-bell" aria-hidden="true"></i>
                  <span className="dot-icon"></span>
                </Nav.Link>
                {/* {balanceUser &&
                <div className="wallet-balance">
                  <Button type="button" variant="unset"><p><img src={require("../assets/images/usd-coin.png")} alt="img" />{balanceUser?balanceUser:0}</p></Button>
                </div>} */}
                {/* {iswallet || walletAddress.length > 0 ?
                  <div className="connect-wallet-btn diconnect-btn">
                    <Nav.Link href="#" onClick={disconnect}>Disconnect</Nav.Link>
                  </div> : null} */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>



      {/* folio-modal */}
      <Modal show={showFolio} onHide={handleCloseFolio} centered className="folio-popup">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <p>Place your first bet to claim a 100 FOLIO welcome bonus! Redeem <a href="/redeem" target="_blank">FOLIO</a> for free NFTs to play cash prize games.</p>
        </Modal.Body>

      </Modal>
    </>
  )
};
export default Header;
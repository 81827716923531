import React from 'react';
import PredictionOptions from './PredictionOptions';
import PredictionBoxBottom from './PredictionBoxBottom';
import dummyImage from '../assets/images/dummy.png'; 
import { baseUrl } from "../config/config";
const PredictionBox = ({ data }) => {
  console.log(" PredictionBox data", data);
  const { prediction_type, image, question, description,access_setting } = data;
  const getImageSrc = () => {
    if (image && image.trim() && image != "undefined" && image != "null") {
      return `${baseUrl}${image}`;
    }
    return dummyImage;
  };

  const trimText = (text, limit) => {
    if (text.length > limit) {
      return `${text.slice(0, limit)}...`;
    }
    return text;
  };
  function removeTags(str) {
    if (!str) return ""; 
    return str.toString().replace(/<[^>]*>/gi, "");
  }
  return (
    <div className="prediction-box">
      {prediction_type === "RISK-FREE" && (
        <div className={`risk-free-batch-listing ${access_setting ==2 ? 'free-access-tag' : ''}`}>
          {/* <p>Prize pool</p> */}
          {access_setting ==2 ? <p>Free access</p> : <p>Prize pool</p>}
        </div>
      )}

      <div className="prediction-market-inner">
        <div className="prediction-market-left">
          <img src={getImageSrc()} alt="predict" />
        </div>
        <div className="prediction-market-right">
          <h5>{trimText(question || '', 50)}</h5>
          <p>{trimText(removeTags(description), 100)}</p>
          <div className="prediction-market-right mid-box-area">
            <PredictionOptions data={data} />
          </div>
        </div>
      </div>
      <PredictionBoxBottom data={data} />
    </div>
  );
};

export default PredictionBox;

import { createContext } from "react";
import swal from "sweetalert";
export const MyContext = createContext();
//Live

// export const apiUrl = "https://api.betfolio.co/api/v1"
// export const baseUrl = "https://api.betfolio.co/"
// export const chainId = 137;
// export const tokenAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";//live mainnet
// export const consttransactionurl =  "https://polygonscan.com/tx/";
// export const metamaskUrl = "https://metamask.app.link/dapp/betfolio.co/"
// export const divideBy = 1000000;
// export const decimalTo = 6;
// export const betCounterAddress = "0x88CD2402f3A486DCd2364c0b0BB9b2C81546b8A3"
// export const soloCounterAddress = "0x1fAA3c5d8692fb1571f8D433B39A1484765cbfD5"  
// export const soloNft = "0x34107Deb9348Aa4daA8e8fAa5dAcaDe2FbbdAFF1"
// export const soloExtendedNft = "0xbeaE75BBd61AC96f1DA1ea07FA1eF8473cF0EAf7"
// export const groupNft = "0x565b610BBEf2ECADCfdc175313190f0143F6E5CB"
// export const multiChoiceNft = "0xF3DcF23919136107535b2728E4627087C4d22b71"
// export const multiChoiceBetCounter = "0x639cc78918a57d43aaf948fde0d02f95055d4005"
// export const appUrl = "https://betfolio.co"
// export const folioContractAddress = '0x2877BC2369a42634becb2d9A4dCf77D97a7230C4';
// export const nftMinterAddress = "0xD590161B4c9Bf3C622F97C90DbB4488273F1f941"

//live 

//staging==Start===>

export const apiUrl = "https://betfolio-api.ecomempire.in/api/v1"
export const baseUrl = "https://betfolio-api.ecomempire.in/"
export const chainId = 137;
 export const appUrl = "https://betfolio.ecomempire.in"
export const tokenAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";//live mainnet
export const consttransactionurl =  "https://polygonscan.com/tx/";
export const metamaskUrl = "https://metamask.app.link/dapp/betfolio.ecomempire.in/"
export const divideBy = 1000000;
export const decimalTo = 6;
export const betCounterAddress = "0x8627e69605adc9317A200A79536577B2c6F1DfDf"
export const soloCounterAddress = "0xC85509fc9518Fa4C60a7Cb95a9aDB5dBd9730207"  
export const soloNft = "0x177Cb9f5Fe566dA86443f54312c0e4F4F9285ce0"
export const soloExtendedNft = "0xBCbb8Ed8Ac13972cC46575D63A080537e233D9A4"
export const groupNft = "0x565b610BBEf2ECADCfdc175313190f0143F6E5CB"
export const multiChoiceNft = "0x85e8Ae1B4d145D7707EA926B21983df7E959F4cb"
export const multiChoiceBetCounter = "0x8F3EFFFD422CD5b1d9fe8497c6f63f05B88F2640"
export const folioContractAddress = '0x2877BC2369a42634becb2d9A4dCf77D97a7230C4';  // mainnet folioContractAddress 
export const nftMinterAddress = "0xD590161B4c9Bf3C622F97C90DbB4488273F1f941"   // mainnet nftMinterAddress
//staging==end


// testing sepola start
// export const apiUrl = "https://test-api.betfolio.co/api/v1"
// export const baseUrl = "https://test-api.betfolio.co/"
// export const appUrl = "https://test.betfolio.co"
// export const chainId = 11155111;
// export const tokenAddress = "0x5E6168E0B90Ec559CF0376c4BFDCa4a5689Ae943";
// export const consttransactionurl =  "https://mumbai.polygonscan.com/tx/";
// export const divideBy = 1000000;
// export const decimalTo = 6;
// export const metamaskUrl = "https://metamask.app.link/dapp/betfolio.ecomempire.in/"
// export const betCounterAddress = "0x4F1CEAA9D4C341633df0dFd9CD29Ebbb4d8B92E0"
// export const soloCounterAddress = "0xD6fc74A611e1A73E57644B47791E674785F15365"
// export const soloNft = "0x4CE9307709D324508b60a627351E86302fe3b711"
// export const soloExtendedNft = "0xDADbAF873940B5C13e91A1e0dC8546199fD08d92"
// export const groupNft = "0xfC4C01F07c021e8466a7F08d9077d65C1EE4C3FA"
// export const multiChoiceNft = "0xe47a3b8EDa249eA364f1a0f5D2499112E8Dcb222"
// export const multiChoiceBetCounter = "0x61870B141748ED88b100AedA0E6429b1038C36Ab"
// export const folioContractAddress = '0x774946f2D38A5c1F98FD47ebf7cDb8b0dDd21FDb';
// export const nftMinterAddress = "0x2dcc86598552ea78ef27c9f3c3c11df50f346ce6"
// testing sepolia End


export const gasFee = 21000
export const gasFeePercent = 1.9
export const folioDecimals = 18;
export const httpproviderPOLY = "https://polygon-mumbai.g.alchemy.com/v2/ZbBgRPLIBdkabBTDqNGXe03I5Lh_pYmO"


export const indexcubeMetamaskUrl = "https://metamask.app.link/dapp/betfolio.co//"
export const Unauthorized=()=>{swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear()
    window.location.href = "/";
    console.log('OK button clicked after error alert');
})}


export function capitalizeFirstLetter(str) {
    console.log("str", str, str.toString());
    str = str.toString();
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatWalletAddress = (address) => {
    if (!address) return '';
    const firstPart = address.slice(0, 4);
    const lastPart = address.slice(-4);
    return `${firstPart}...${lastPart}`;
};




export const commissionArray = [{amountmin:0,amountmax:1000,commission:3},{amountmin:1001,amountmax:5000,commission:2,
},{amountmin:5001,amountmax:10000,commission:1},{amountmin:10001,amountmax:1000000000000,commission:0.5}]
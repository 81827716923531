import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row, Table, Modal } from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../service/api.service";
import swal from "sweetalert";
import { folioContractAddress, formatWalletAddress, folioDecimals, nftMinterAddress } from "../config/config";
import Web3 from 'web3';
import NftReddemabi from '../common/NftReddemabi.json'
import { tokenAbi } from "../config/tokenAbi";
import { formatBalanceWithDecimals } from "../common/connectWallet";
import Pagination from "react-js-pagination";
import { adClient } from '../common/adConfig'
// 1 solo -- 200 folio
// 2 extended -- 400 
// 3 multichoice -- 100



const Redem = () => {
    const [balance, setBalance] = useState(0);
    const [activePercentage, setActivePercentage] = useState(10);
    const [inputValue, setInputValue] = useState("");
    const [loader, setLoader] = useState(false);
    const [folioValue, setFolioValue] = useState("");
    const [redmeeList, setRedmeeList] = useState([]);
    const [filter, setFilter] = useState("All");
    const [folioBalanceWei, setFolioBalanceWei] = useState(0)
    const [folioAllowance, setFolioAllowance] = useState(0)
    const [showStepsModal, setShowStepsModal] = useState(false)
    const [tokenApproved, setTokenApproved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nftMint, setNftMint] = useState()
    const [userRedemptions, setUserRedemptions] = useState()
    const [totalFolioRedeemed, setTotalFolioRedeemed] = useState(0)
    const [refreshHook, setRefreshHook] = useState(); // update useeffect
    const [totalCount, settotalCount] = useState()
    const [page, setpage] = useState(0)

    const USER_MONTHLY_REEDEM_LIMIT = 500;
    const MONTHLY_PLATFORM_REEDEM_LIMIT = 500000;

    const percentages = [10, 20, 50, 100];
    const isConnected = !!localStorage.getItem("connected_wallet");
    const handleCloseStepsModal = () => setShowStepsModal(false);
    const userAddress = localStorage.getItem("connected_wallet");

    const NFT_NAME = ["Solo", "Extended solo", "Multi Choice"]


    useEffect(() => {
        const requiredFolio = nftMint === 1 ? 200 : nftMint === 3 ? 100 : 400;
        setTokenApproved(folioAllowance >= web3.utils.toWei(requiredFolio.toString(), "ether"));
    }, [folioAllowance, nftMint]);



    useEffect(() => {
        // Calculate the default values based on the default percentage
        const defaultPercentage = 10;
        const calculatedValue = (balance * defaultPercentage) / 100;
        setActivePercentage(defaultPercentage);
        setInputValue(parseInt(calculatedValue));
        setFolioValue(parseInt(calculatedValue));
    }, [balance]);

    useEffect(() => {
        console.log("refreshHook");
        get_userPoints();
        get_RedmeeList(filter, page);
        getFolioBalance();
    }, [refreshHook]);

    function handlePageChange(pageNumber) {
        console.log("??????page", pageNumber)
        if (page != pageNumber - 1) {
            setpage(pageNumber - 1);
            get_RedmeeList(filter, (pageNumber - 1));
        }

    }



    const handlePercentageClick = (percentage) => {
        const calculatedValue = (balance * percentage) / 100;
        setActivePercentage(percentage);
        setInputValue(parseInt(calculatedValue));
        setFolioValue(parseInt(calculatedValue));
    };

    // const handleInputChange = (e) => {
    //     const value = e.target.value;
    //     setInputValue(value);
    //     setFolioValue(value); // 1 point equals 1 folio
    // };
    const handleInputChange = (e) => {
        let value = e.target.value;
    
        // Prevent leading space
        if (value.length === 1 && value === " ") {
            value = ""; // Ignore the input if it's a leading space
        }
    
        // Allow only numbers and spaces (but no leading spaces)
        value = value.replace(/[^0-9 ]/g, "");
    
        setInputValue(value);
        setFolioValue(value.trim()); // 1 point equals 1 folio, trim any trailing spaces
    };
    
    

    const PointsProgram = [
        { value: "All", label: "All" },
        { value: "Own", label: "My Account" },
    ];




    const redeemFolio = async (nft_type) => {

        const requiredFolio = nft_type === 1 ? 200 : nft_type === 3 ? 100 : 400;
        const useralreadyReddem = formatBalanceWithDecimals(userRedemptions.amount, folioDecimals);
        console.log("useralreadyReddem", useralreadyReddem);
        const user_remaining_limit = (USER_MONTHLY_REEDEM_LIMIT - useralreadyReddem);
        console.log("requiredFolio>>>>>>>>>", USER_MONTHLY_REEDEM_LIMIT, requiredFolio, user_remaining_limit);
        console.log("user_remaining_limit>>>>>>>>>", user_remaining_limit);
        if (parseInt(parseInt(formatBalanceWithDecimals(totalFolioRedeemed, folioDecimals)) + requiredFolio) >= MONTHLY_PLATFORM_REEDEM_LIMIT) {
            swal("The platform's monthly usage limit has been exceeded.");
            return
        }

        if (user_remaining_limit == 0) {
            swal(`You have exhausted your monthly limit.`);
            return
        }

        console.log("user_remaining_limit is>>>", user_remaining_limit, "Required folio is", requiredFolio);
        if (requiredFolio > user_remaining_limit) {
            swal(`You can redeem only ${user_remaining_limit} out of ${USER_MONTHLY_REEDEM_LIMIT} montly limit.`);
            return
        }

        setTokenApproved(false)
        setShowStepsModal(true);
        setIsLoading(true); // Set the loader for the modal
        setNftMint(nft_type);
        try {

            // Check allowance
            const allowance = await folioContract.methods.allowance(userAddress, nftMinterAddress).call();
            console.log("allowance>>>>>", allowance);
            setFolioAllowance(parseInt(allowance));
            if (parseInt(allowance) >= web3.utils.toWei(requiredFolio.toString(), "ether")) {
                // If token is already approved, proceed to mint the NFT
                setTokenApproved(true);
                await mintNFT(nft_type);
            } else {
                // If not approved, approve the tokens
                await approveFolio(requiredFolio);
                setTokenApproved(true); // Set tokenApproved to true after successful approval
                // Proceed to mint the NFT after approval
                await mintNFT(nft_type);
            }
        } catch (error) {
            console.log("Error in redeeming FOLIO:", error);
            swal("Error", error.message, "error");
        } finally {
            setIsLoading(false);
            setShowStepsModal(false); // Close the modal
        }
    };

    // Function to approve the required FOLIO tokens
    const approveFolio = async (amount) => {
        try {
            const weiAmount = web3.utils.toWei("10000000000000000000000", "ether");
            await folioContract.methods.approve(nftMinterAddress, weiAmount).send({ from: userAddress });
            // swal("Success", "FOLIO approved successfully.", "success");
        } catch (error) {
            console.error("Error approving FOLIO:", error);
            // swal("Error", "Minting failed. Please try again.", "error");
            throw new Error("Approval failed. Please try again.");
        }
    };

    // Function to mint the NFT
    const mintNFT = async (nft_type) => {
        try {
            // Assuming you have the nftMinterContract set up with the ABI and address
            const nftMinterContract = new web3.eth.Contract(NftReddemabi, nftMinterAddress);
            await nftMinterContract.methods.mintNFT(nft_type).send({ from: userAddress });
            setRefreshHook(new Date());
            swal("Success", "NFT minted successfully!", "success");
        } catch (error) {
            console.error("Error minting NFT:", error);
            swal("Error", "Minting failed. Please try again.", "error");
        }
    };



    /* convert points to folio */
    const handleConvertRedeem = async () => {
        console.log("handleConvertRedeem");
        const params = {
            points: inputValue,
        }
        try {
            setLoader(true);
            const response = await apiService.convertRedeem(params);
            if (response.status == 200) {
                swal({
                    title: 'Success!',
                    text: 'FOLIO request sent successfully. It may take some time to appear on the listing.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#3085d6',
                    backdrop: true,
                    position: 'center',
                    timer: 3000, // Optional: auto-close after 3 seconds
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Handle what happens after the user clicks 'OK'
                    }
                });
                get_RedmeeList(filter, page); // change here
                console.log(response.data);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                localStorage.clear();
                window.location.href = "/";
            } else {
                // Handle other errors
                console.log("error>>>>>", error);
                swal({ icon: "error", text: error?.response?.data?.error, button: "OK" })


            }
        }
    };

    const web3 = new Web3(window.ethereum);

    // Request account access
    window.ethereum?.request({ method: 'eth_requestAccounts' }).catch((error) => {
        console.error('User denied account access:', error);
    });
    console.log("folioContractAddress", folioContractAddress);


    const folioContract = new web3.eth.Contract(tokenAbi, folioContractAddress);
    // Function to get the balance of a specific address
    const getFolioBalance = async () => {
        try {
            const balance = await folioContract.methods.balanceOf(userAddress).call();
            const approvedFolio = await folioContract.methods.allowance(userAddress, nftMinterAddress).call();
            console.log("approvedFolio", approvedFolio);
            console.log(`Folio Balance of ${userAddress}: ${balance}`);
            setFolioBalanceWei(parseInt(balance))
            setFolioAllowance(parseInt(approvedFolio))
            return balance;
        } catch (error) {
            console.error('Error fetching balance:', error);
        }
    };
    /* get user points */
    const get_userPoints = async () => {
        setLoader(true);
        try {
            const response = await apiService.getRedmeedPoints();
            if (response.status === 200) {
                console.log("response.data.data>>>>>get_userPoints", response.data);
                setUserRedemptions(response.data?.data?.userRedemptions);
                setBalance((parseInt(response?.data?.data?.rewardPoints?.points) - (parseInt(response?.data?.data?.rewardPoints?.redemmed_points) || 0)) || 0)
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            // if (error?.response?.status === 401) {
            //     swal({ text: "Unauthorized", button: "OK" }).then(() => {
            //         localStorage.clear();
            //         window.location.href = "/";
            //     });
            // }
        }
    };

    /* get redmee list */
    const get_RedmeeList = async (filterKey = "All", pageSel) => {
        setLoader(true);
        try {
            const response = await apiService.getRedmeedList(filterKey, pageSel);
            if (response.status === 200) {
                console.log("get_RedmeeList .data>>>>>", response.data?.data);
                setRedmeeList(response?.data?.data?.folioPoints);
                setLoader(false);
                settotalCount(response?.data?.data?.totalRecordsCount)
                setTotalFolioRedeemed(response?.data?.data?.totalFolioRedeemed);
            }
        } catch (error) {
            setLoader(false);
            // if (error?.response?.status === 401) {
            //     swal({ text: "Unauthorized", button: "OK" }).then(() => {
            //         localStorage.clear();
            //         window.location.href = "/";
            //     });
            // }
        }
    };

    const handleFilterChange = (selectedOption) => {
        setFilter(selectedOption.value);
        setpage(0);
        get_RedmeeList(selectedOption.value, page);

    };


    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "271833e4-a718-4d71-b73e-955e35b1b33d",
            containerId: "ads-banner-top"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);

    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "7ef30ab6-59e1-45b9-9072-591153082a1e",
            containerId: "ads-home-right"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);

    useEffect(() => {
        adClient.showBannerAd({
            adUnitId: "bcfbb4d5-0c6c-49e4-804f-2eb1a21e61f0",
            containerId: "ads-home-left"
        }, (errorMessage) => {
            console.log("errorMessage", errorMessage);
            // You can handle error here.
        })
    }, []);

    return (
        <>
            <div className="ads-banner-top" id="ads-banner-top">

            </div>
            <div id="ads-home-right">

            </div>
            <div id="ads-home-left">

            </div>
            <section className="redem-outer-area">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12} lg={10} xxl={10}>
                            <div className="redem-inner-area">
                                <h2>FOLIO Redemption</h2>
                                <div className="redem-top-info">
                                    <h6>What is FOLIO?</h6>
                                    <p>FOLIO are tokenized points redeemable for BetFolio NFTs which allow you to bet on <a href="https://betfolio.co/solo-predictions" target="blank">Solo</a> and <a href="https://betfolio.co/multi-choice-predictions" target="blank">Multichoice</a> predictions for free.</p>
                                    <p>You can earn FOLIO by accumulating points when you place or win a bet on any prediction. Once you have minimum 100 points, you can convert it to FOLIO.
                                        You can then redeem your FOLIO for an NFT on this page.</p>
                                    <ul>
                                        <li>The maximum amount of FOLIO that can be redeemed by a single user in a 30 day period is 500 FOLIO.</li>
                                        {/* <li> The maximum amount of FOLIO that can be redeemed by all users in the platform in a 30 day period is 500,000 FOLIO. </li> */}
                                    </ul>
                                </div>
                                <Row className="justify-content-center">
                                    <Col md={6} lg={4} className="mb-2">
                                        <div className="redem-points-area balance-area">
                                            <h6>Balance: <span>{balance}</span> pts</h6>
                                            <p>Points</p>
                                            <Form.Control
                                                type="text"
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                placeholder="100"
                                                maxLength={10}
                                            />
                                            <ul>
                                                {percentages.map((percentage, index) => (
                                                    <li
                                                        key={index}
                                                        className={activePercentage === percentage ? "active" : ""}
                                                        onClick={() => handlePercentageClick(percentage)}
                                                    >
                                                        {percentage}%
                                                    </li>
                                                ))}
                                            </ul>
                                            <img src={require("../assets/images/double-direction-arrow.svg").default} alt="Double Direction Arrow" />
                                        </div>
                                    </Col>
                                    <Col md={6} lg={4}>
                                        <div className="redem-points-area">
                                            <p>FOLIO</p>
                                            <h3>{folioValue}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Button type="button" variant="unset" disabled={!isConnected || inputValue < 1 || inputValue > balance || balance < 100} onClick={handleConvertRedeem}>Convert to FOLIO</Button>
                                <h6>1 Point on BetFolio = 1 FOLIO<span>*</span></h6>
                            </div>
                            <hr />
                            <Row className="mb-4 justify-content-center">
                                <Col sm={12} md={9} lg={7} xl={6} xxl={5}>
                                    <div className="redem-points-area folio-amount-area">
                                        <p>FOLIO Balance: <span>{formatBalanceWithDecimals(folioBalanceWei, folioDecimals)}</span> FOLIO</p>
                                        <div className="redem-button">
                                            <Button type="button" variant="unset" disabled={formatBalanceWithDecimals(folioBalanceWei, folioDecimals) < 100} onClick={() => redeemFolio(3)} >Multichoice NFT</Button>
                                            <Button type="button" variant="unset" disabled={formatBalanceWithDecimals(folioBalanceWei, folioDecimals) < 200} onClick={() => redeemFolio(1)} >Solo NFT</Button>
                                            <Button type="button" variant="unset" disabled={formatBalanceWithDecimals(folioBalanceWei, folioDecimals) < 400} onClick={() => redeemFolio(2)}>Extended Solo NFT</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-4 justify-content-center">
                                <Col md={12}>
                                    <div className="redemption-options">
                                        <h6><i className="fa fa-info-circle" aria-hidden="true"></i> You can only redeem 1 NFT at a time. Select from the 3 redemption options:</h6>
                                        <ul>
                                            <li>You need minimum <span>100 FOLIO</span> to redeem for <span>1 Multichoice NFT.</span></li>
                                            <li>You need minimum <span>200 FOLIO</span> to redeem for<span>1 Solo NFT.</span></li>
                                            <li>You need minimum <span>400 FOLIO</span> to redeem for <span>1 Extended Solo NFT.</span></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col md={12}>
                                    <div className="redem-history">
                                        <h5>Redeem History</h5>
                                    </div>
                                    <div className="audit-request-box leaderboard-inner">
                                        <div className="board-filters redem-history-filter">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Status:</Form.Label>
                                                <Select
                                                    options={PointsProgram}
                                                    placeholder="All"
                                                    isDisabled={!isConnected}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            background: "#1d0042",
                                                            border: "1px solid rgb(255 255 255 / 50%)",
                                                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
                                                            borderRadius: "10px",
                                                            "&:focus": {
                                                                borderColor: "rgb(255 255 255 / 50%)",
                                                            },
                                                            "&:hover": {
                                                                borderColor: "rgb(255 255 255 / 50%)",
                                                            },
                                                        }),
                                                    }}
                                                    onChange={handleFilterChange}
                                                />
                                            </Form.Group>
                                        </div>
                                        <Table responsive="sm" className="UserListTable">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th className="leaderboard-address">Wallet Address</th>
                                                    <th className="leaderboard-address">Redeem</th>
                                                    <th className="leaderboard-address">Collected</th>
                                                    <th className="leaderboard-address">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {redmeeList?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{(page * 10) + index + 1}</td>
                                                            <td>{formatWalletAddress(item.user_wallet_address)}</td>

                                                            {item.type == "point" ?
                                                                <>
                                                                    <td>{item.points_redeem + " points" || "-"}</td>
                                                                    <td>{item.folio_points + " FOLIO" || "-"}</td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>{item.points_redeem + "FOLIO" || "-"}</td>
                                                                    <td>{NFT_NAME[(item.folio_points || 0) - 1]}</td>
                                                                </>

                                                            }
                                                            <td>{item?.status || "-"}</td>
                                                        </tr>

                                                    )
                                                })}
                                                {redmeeList && redmeeList.length == 0 &&
                                                    <tr>
                                                        <td className="board-no-data-outer">
                                                            <div className="no-data-area main-no-data table-no-data board-no-data">
                                                                <img src={require("../assets/images/leader-board-no-data.png")} alt="img" />
                                                                <p> No Data Found</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                        {redmeeList && redmeeList.length > 0 ?
                                            <Pagination
                                                activePage={page + 1}
                                                itemsCountPerPage={10}
                                                totalItemsCount={totalCount}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => handlePageChange(e)}
                                                prevPageText={"Prev"}
                                                nextPageText={"Next"}
                                            />
                                            : null
                                        }


                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal
                show={showStepsModal}
                backdrop="static"
                onHide={handleCloseStepsModal}
                centered
                className="connect-wallet-box follow-steps-popup"
            >
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="mt-0">Follow Steps </h3>
                    <div className="step-area">
                        <div className="steps-left-area">
                            {/* <h5>1</h5> */}
                            {!tokenApproved && isLoading ? (
                                <div className="loader"></div>
                            ) : (
                                <h2>
                                    ✓
                                </h2>
                            )}
                        </div>

                        <div className="steps-content">
                            <h6> Approve FOLIO</h6>
                            <p>Approving FOLIO</p>
                        </div>
                    </div>
                    <div className="step-area">
                        <div className="steps-left-area">
                            {tokenApproved && isLoading ? (
                                <div className="loader"></div>
                            ) : (
                                !tokenApproved && isLoading ? <h2>2</h2> :
                                    <h2>
                                        ✓
                                    </h2>
                            )}
                        </div>

                        <div className="steps-content">
                            <h6> Mint NFT</h6>
                            <p>Send transaction to mint nft</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    );
}

export default Redem;
